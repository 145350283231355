// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-doc-article-page-tsx": () => import("./../../../src/pages/doc-article-page.tsx" /* webpackChunkName: "component---src-pages-doc-article-page-tsx" */),
  "component---src-pages-doc-article-tsx": () => import("./../../../src/pages/doc-article.tsx" /* webpackChunkName: "component---src-pages-doc-article-tsx" */),
  "component---src-pages-doc-events-tsx": () => import("./../../../src/pages/doc-events.tsx" /* webpackChunkName: "component---src-pages-doc-events-tsx" */),
  "component---src-pages-doc-projects-tsx": () => import("./../../../src/pages/doc-projects.tsx" /* webpackChunkName: "component---src-pages-doc-projects-tsx" */),
  "component---src-pages-doc-tsx": () => import("./../../../src/pages/doc.tsx" /* webpackChunkName: "component---src-pages-doc-tsx" */),
  "component---src-pages-doc-videos-tsx": () => import("./../../../src/pages/doc-videos.tsx" /* webpackChunkName: "component---src-pages-doc-videos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

